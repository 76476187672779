<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex v-if="item.finishByExpert" xs12>
                    <v-alert dense type="success"> Finalizado pelo especialista </v-alert>
                </v-flex>
                <v-flex xs12>
                    <ExpertList :value="item.expert" @change="item.expert = $event" />
                </v-flex>
                <v-flex xs12>
                    <v-autocomplete v-model="item.store" :items="stores" item-value="id" item-text="name" :label="$t('Lojas')" />
                </v-flex>

                <v-flex xs12 my-4>
                    <v-layout wrap>
                        <v-flex xs12 sm6>
                            <v-text-field :label="$t('Coordenada de entrada')" v-model="item.coordinateIn" append-outer-icon="navigation" :rules="[rules.coordinate]"
                                @click:append-outer="navegate(item.coordinateIn)" />
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field :label="$t('Coordenada de saída')" v-model="item.coordinateOut" append-outer-icon="navigation" :rules="[rules.coordinate]"
                                @click:append-outer="navegate(item.coordinateOut)" />
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12 my-4>
                    <v-layout wrap>
                        <v-flex xs12 sm3>
                            <v-text-field inputmode="numeric" ref="dateIn" :label="$t('Hora entrada')" v-model="dateIn" :rules="[rules.dateTime]" v-mask="'##/##/#### ##:##'"
                                clearable />
                            <div class="text-caption">Trabalhado: {{ getWorkerTime }}</div>
                        </v-flex>
                        <v-flex xs12 sm3>
                            <v-text-field inputmode="numeric" ref="dateInLunch" :label="$t('Entrada almoço')" v-model="dateInLunch" :rules="[rules.dateTime]"
                                v-mask="'##/##/#### ##:##'" clearable />
                        </v-flex>
                        <v-flex xs12 sm3>
                            <v-text-field inputmode="numeric" ref="dateOutLunch" :label="$t('Volta almoço')" v-model="dateOutLunch" :rules="[rules.dateTime]"
                                v-mask="'##/##/#### ##:##'" clearable />
                        </v-flex>
                        <v-flex xs12 sm3>
                            <v-text-field inputmode="numeric" ref="dateOut" :label="$t('Hora saida')" v-model="dateOut" :rules="[rules.dateTime]" v-mask="'##/##/#### ##:##'"
                                clearable />
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs6>
                    <v-text-field :label="$t('Número entregas')" type="number" v-model="item.deliveryNumber" />
                </v-flex>

                <v-flex xs12>
                    <v-layout wrap>
                        <v-flex xs12 md6 v-for="modal in item.modals" :key="modal">
                            <v-alert shaped icon="electric_moped" outlined type="success">
                                <strong v-if="item.deliveryMode == 'OwnVehicle'">Trabalhando de veículo próprio</strong>
                                <strong v-else-if="item.deliveryMode == 'OwnMotorcycle'">Trabalhando de moto própria</strong>
                                <strong v-else-if="item.deliveryMode == 'StoreMotorcycle' && modal.modal">
                                    Scooter eletrica: {{ modal.modal.plate }} {{ modal.modal.serialNumber }}
                                </strong>
                                <strong v-else-if="item.deliveryMode == 'StoreVehicle' && modal.modal">
                                    Veículo: {{ modal.modal.plate }} {{ modal.modal.serialNumber }}
                                </strong>

                                <v-text-field type="number" :label="$t('Odometro entrada')" suffix="Km" v-model="modal.odometerIn" />
                                <v-text-field type="number" :label="$t('Odometro saída')" suffix="Km" v-model="modal.odometerOut" />
                                <div class="text-caption">Percorrido: {{ getRoad(modal.odometerIn, modal.odometerOut) }} km</div>
                            </v-alert>
                        </v-flex>
                        <v-flex xs12 v-if="item.deliveryMode == 'Bike'">
                            <v-alert shaped icon="directions_bike" outlined type="success">
                                <div class="text-caption"><strong>Trabalhando de bike</strong></div>
                            </v-alert>
                        </v-flex>

                        <v-flex xs12 md6 v-for="battery in item.batteries" :key="battery">
                            <v-alert shaped icon="battery_std" outlined type="success">
                                <strong>
                                    <div v-if="battery.battery">
                                        <div class="d-inline">Bateria:</div>
                                        <div class="d-inline" v-if="battery.battery.tag">{{ battery.battery.tag }}</div>
                                        <div class="d-inline" v-else-if="battery.battery.name">{{ battery.battery.name }}</div>
                                    </div>
                                </strong>

                                <v-text-field type="number" :label="$t('Porcentagem ao pegar bateria')" suffix="%" v-model="battery.percentStart" />
                                <v-text-field type="number" :label="$t('Porcentagem ao devolver bateria')" suffix="%" v-model="battery.percentStop" />
                            </v-alert>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12>
                    <v-textarea :label="$t('Observações')" v-model="item.notes"></v-textarea>
                </v-flex>
                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import ExpertList from "@/components/shared/ExpertList";

export default {
    directives: { mask },

    components: { ExpertList },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                user: null,
                store: null,
            },
            item: { ...this.itemClean },
            stores: null,
            experts: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    computed: {
        dateIn: {
            get: function () {
                return this.$moment(this.item.dateIn).format("DD/MM/YYYY HH:mm");
            },
            set: function (newValue) {
                if (this.$refs.dateIn.validate() && newValue != null && newValue.length > 0) {
                    this.item.dateIn = this.$moment(newValue, "DD/MM/YYYY HH:mm");
                } else if (newValue == null && this.item.dateIn != null) {
                    this.item.dateIn = null;
                }
            },
        },
        dateInLunch: {
            get: function () {
                return this.$moment(this.item.dateInLunch).format("DD/MM/YYYY HH:mm");
            },
            set: function (newValue) {
                if (this.$refs.dateInLunch.validate() && newValue != null && newValue.length > 0) {
                    this.item.dateInLunch = this.$moment(newValue, "DD/MM/YYYY HH:mm");
                } else if (newValue == null && this.item.dateInLunch != null) {
                    this.item.dateInLunch = null;
                }
            },
        },
        dateOutLunch: {
            get: function () {
                return this.$moment(this.item.dateOutLunch).format("DD/MM/YYYY HH:mm");
            },
            set: function (newValue) {
                if (this.$refs.dateOutLunch.validate() && newValue != null && newValue.length > 0) {
                    this.item.dateOutLunch = this.$moment(newValue, "DD/MM/YYYY HH:mm");
                } else if (newValue == null && this.item.dateOutLunch != null) {
                    this.item.dateOutLunch = null;
                }
            },
        },
        dateOut: {
            get: function () {
                return this.$moment(this.item.dateOut).format("DD/MM/YYYY HH:mm");
            },
            set: function (newValue) {
                if (this.$refs.dateOut.validate() && newValue != null && newValue.length > 0) {
                    this.item.dateOut = this.$moment(newValue, "DD/MM/YYYY HH:mm");
                } else if (newValue == null && this.item.dateOut != null) {
                    this.item.dateOut = null;
                }
            },
        },
        getWorkerTime() {
            if (this.item.dateOut) {
                if (this.item.dateInLunch && this.item.dateOutLunch) {
                    let date1 = new Date(this.item.dateIn);
                    let date2 = new Date(this.item.dateInLunch);
                    let date3 = new Date(this.item.dateOutLunch);
                    let date4 = new Date(this.item.dateOut);

                    let hour = parseInt((date2 - date1 + (date4 - date3)) / (1000 * 60 * 60));
                    let minutes = parseInt(((date2 - date1 + (date4 - date3)) / (1000 * 60)) % 60);
                    return `${hour}h ${minutes}m`;
                } else {
                    let date1 = new Date(this.item.dateIn);
                    let date2 = new Date(this.item.dateOut);

                    let hour = parseInt((date2 - date1) / (1000 * 60 * 60));
                    let minutes = parseInt(((date2 - date1) / (1000 * 60)) % 60);
                    return `${hour}h ${minutes}m`;
                }
            }
            return " ???";
        },
        getRoadTotal() {
            if (this.item.odometerOut) {
                return this.item.odometerOut - this.item.odometerIn;
            }
            return " ???";
        },
    },
    methods: {
        getRoad(odometerIn, odometerOut) {
            if (odometerIn && odometerOut) {
                return odometerOut - odometerIn;
            }
            return " ???";
        },
        navegate(coordinate) {
            window.open(`https://maps.google.com/maps?daddr=${coordinate}&amp;ll=`);
        },
        getStores() {
            this.$http
                .get('stores?enable=true')
                .then((result) => {
                    if (result) {
                        this.stores = result;
                    }
                })
                .catch(() => {
                    this.stores = null;
                });
        },
        getExperts() {
            this.$http
                .get(`experts?status=5`)
                .then((result) => {
                    if (result) {
                        this.experts = result;
                    }
                })
                .catch(() => {
                    this.experts = null;
                });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        this.getStores();
        this.getExperts();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>